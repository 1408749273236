import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getOffers, deleteOffer } from '../../../actions/offer';
import { Link } from 'react-router-dom';
import './admin.scss';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const AdminPanel = ({
  offers,
  soldOffers,
  activeOffers,
  getOffers,
  deleteOffer,
}) => {
  const [showSold, setShowSold] = useState(false);
  useEffect(() => {
    getOffers();
  }, []);

  const handleChangeOffers = (e) => {
    setShowSold((prevState) => !prevState);
  };

  const deleteOffers = async (id) => {
    const res = await deleteOffer(id);
    if ((res.status = 200)) {
      getOffers();
    }
  };

  const handleDeleteOfferr = (id, name) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Zostaw',
        },
        {
          label: 'Usuń',
          onClick: () => deleteOffer(id),
        },
      ],
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Jesteś pewien że chcesz usunąć ofertę ?</h1>
            <div>{name} </div>
            <button onClick={onClose}>Nie usuwaj</button>
            <button
              onClick={() => {
                deleteOffers(id);
                onClose();
              }}
            >
              Usuń
            </button>
          </div>
        );
      },
    });
  };

  return (
    <div className='admin'>
      <div className='container '>
        <div className='box'>
          <div className='level'>
            <div className='level-left'>
              <Link className='button is-prmiary ' to='/admin/create'>
                Utwórz
              </Link>
            </div>
            <button
              className='level-right button is-info'
              onClick={(e) => handleChangeOffers(e)}
            >
              {showSold && offers.length > 0 ? 'Aktywne oferty' : 'Sprzedane'}
            </button>
          </div>
        </div>

        <div className='box admin__offers'>
          <div className='columns is-multiline'>
            {!showSold ? (
              activeOffers.length > 0 ? (
                activeOffers.map((offer) => {
                  return (
                    <div className='column is-4'>
                      <div className='admin__item'>
                        <div className='admin__title'>{offer.name}</div>
                        <div className='admin__img-wrapper'>
                          <img
                            className='admin__img'
                            src={offer.thumbnail}
                            alt={offer.name}
                          />
                          <Link to={`oferta/${offer.url}`}>
                            <i
                              className='fas fa-eye'
                              title='podgląd oferty'
                            ></i>
                          </Link>
                          {offer.publish && (
                            <i
                              className='fas fa-check-square'
                              title='oferta jest opublikowana'
                            ></i>
                          )}
                        </div>
                        <div className='admin__buttons-wrapper'>
                          <Link
                            className='button is-info'
                            to={`admin/editOffer/${offer._id}`}
                          >
                            Edytuj
                          </Link>
                          <button
                            className='button is-danger'
                            onClick={() =>
                              handleDeleteOfferr(offer._id, offer.name)
                            }
                          >
                            Usuń
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='column'>
                  <div className='admin-panel__no-offer'>
                    <span className='h2-like'>
                      Nie mamy żadnych aktywnych ofert
                    </span>
                    <i className='fas fa-vihara'></i>
                  </div>
                </div>
              )
            ) : soldOffers.length > 0 ? (
              soldOffers.map((offer) => {
                return (
                  <div className='column is-4'>
                    <div className='admin__item'>
                      <div className='admin__title'>{offer.name}</div>
                      <div className='admin__img-wrapper'>
                        <img
                          className='admin__img'
                          src={offer.thumbnail}
                          alt={offer.name}
                        />
                        <Link to={`oferta/${offer.url}`}>
                          <i className='fas fa-eye' title='podgląd oferty'></i>
                        </Link>
                        {offer.publish && (
                          <i
                            className='fas fa-check-square'
                            title='oferta jest opublikowana'
                          ></i>
                        )}
                      </div>
                      <div className='admin__buttons-wrapper'>
                        <Link
                          className='button is-info'
                          to={`admin/editOffer/${offer._id}`}
                        >
                          Edytuj
                        </Link>
                        <button
                          className='button is-danger'
                          onClick={() =>
                            handleDeleteOfferr(offer._id, offer.name)
                          }
                        >
                          Usuń
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='column'>
                <div className='admin-panel__no-offer'>
                  <span className='h2-like'>'Nie żadnych sprzedanych'</span>
                  <i className='fas fa-vihara'></i>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  offers: state.offer.offers,
  soldOffers: state.offer.soldOffers,
  activeOffers: state.offer.activeOffers,
});

export default connect(mapStateToProps, { getOffers, deleteOffer })(AdminPanel);
