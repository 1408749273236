import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getOffer, postEditOffer, postThumbnail } from '../../../actions/offer';
import { setAlert } from '../../../actions/alert';
import { Redirect } from 'react-router-dom';
import './editOffer.scss';
import * as Utils from '../../../utilities/Utils';

const EditOffer = ({
  getOffer,
  postEditOffer,
  id,
  postThumbnail,
  setAlert,
}) => {
  const [areaValue, setAreaValue] = useState(null);
  const [pathsToDelete, setPathsToDelete] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [offerToEdit, setOfferToEdit] = useState({
    path: [],
    pathsToDelete: [],
    additionalInfo: [{}],
    description: [],
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const offertTE = await getOffer(id);
      const { additionalInfo, description } = offertTE.data;
      if (offertTE.data) {
        const takeInfo = additionalInfo[0];
        setOfferToEdit({ ...offertTE.data, additionalInfo: takeInfo });
        setAreaValue(description.join('\n\n'));
      } else {
        setRedirect(true);
      }
    };
    fetchData();
  }, []);

  const deleteImage = (e) => {
    e.preventDefault();
    const imgId = e.currentTarget.dataset.path;
    const pathToDelete = offerToEdit.path.filter((item) => {
      return item.original === imgId;
    });

    const newPaths = offerToEdit.path.filter((item) => {
      return item.original !== imgId;
    });

    const pathTD = pathsToDelete;
    pathTD.push(pathToDelete[0]);
    setPathsToDelete(pathTD);

    setOfferToEdit({
      ...offerToEdit,
      path: newPaths,
      pathsToDelete: pathTD,
    });
  };

  const addDescription = (e) => {
    e.preventDefault();
    var desc = document.getElementById('description').value;

    const textArray = desc.split('\n').filter((text) => {
      return text !== '';
    });

    setAreaValue(desc);

    setOfferToEdit({ ...offerToEdit, description: textArray });
  };

  const onChange = (e) => {
    setOfferToEdit({ ...offerToEdit, [e.target.name]: e.target.value });
  };

  const check = (e) => {
    setOfferToEdit({
      ...offerToEdit,
      [e.target.name]: e.target.checked,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const images = document.getElementById('file');
    const thumbnail = document.getElementById('thumbnail');

    //Delete empty values from additional info
    Object.keys(offerToEdit.additionalInfo).forEach(
      (key) =>
        (offerToEdit.additionalInfo[key] === '' ||
          offerToEdit.additionalInfo[key] === null) &&
        delete offerToEdit.additionalInfo[key]
    );

    const data = new FormData();
    data.append('id', offerToEdit._id);
    data.append('name', offerToEdit.name);
    data.append('url', offerToEdit.url);
    data.append('sold', offerToEdit.sold);
    data.append('map', offerToEdit.map);
    data.append('price', offerToEdit.price);
    data.append('order', offerToEdit.order);
    data.append('publish', offerToEdit.publish);
    data.append('additionalInfo', offerToEdit.additionalInfo);
    data.append('path', JSON.stringify(offerToEdit.path));
    data.append('description', JSON.stringify(offerToEdit.description));

    if (offerToEdit.pathsToDelete) {
      data.append('fileToDelete', JSON.stringify(offerToEdit.pathsToDelete));
    }

    for (var i = 0; i < images.files.length; i++) {
      data.append('image', images.files[i]);
    }

    const postOffer = await postEditOffer(data);
    let thumbnailPosted = null;
    if (thumbnail.files.length > 0) {
      const thumbnailData = new FormData();
      thumbnailData.append('id', offerToEdit._id);
      thumbnailData.append('name', offerToEdit.name);
      thumbnailData.append('thumbnail', thumbnail.files[0]);
      thumbnailData.append('isEdit', true);
      thumbnailPosted = await postThumbnail(thumbnailData);

      if (postOffer.status === 200 && thumbnailPosted.status === 200) {
        setAlert('Oferta z edytowna', 'success');
        setRedirect(true);
      } else {
        setAlert('Coś poszło nie tak', 'danger');
      }
    } else {
      if (postOffer.status === 200) {
        setAlert('Oferta z edytowna', 'success');
        setRedirect(true);
      } else {
        setAlert('Coś poszło nie tak', 'danger');
      }
    }
    setLoader(false);
  };

  const onChangeInfo = (e) => {
    const names = [...document.querySelectorAll('[name=additionalInfo-name]')];
    const values = [
      ...document.querySelectorAll('[name=additionalInfo-value]'),
    ];
    const result = {};
    names.forEach((key, i) => (result[key.value] = values[i].value));
    setOfferToEdit({ ...offerToEdit, additionalInfo: result });
  };

  const handleImageChange = (e) => {
    setOfferToEdit({
      ...offerToEdit,
      thumbnail: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleImages = (e) => {
    const files = [...e.currentTarget.files];
    var newImages = files.map((item) => {
      return URL.createObjectURL(item);
    });

    setOfferToEdit({
      ...offerToEdit,
      photos: newImages,
    });
  };

  const addNewInput = (e) => {
    e.preventDefault();
    const emptyObj = {
      '': '',
    };

    const newObj = Object.assign({}, offerToEdit.additionalInfo, emptyObj);

    setOfferToEdit({ ...offerToEdit, additionalInfo: newObj });
  };

  const {
    name,
    url,
    sold,
    map,
    price,
    publish,
    additionalInfo,
    thumbnail,
    path,
    order,
  } = offerToEdit;

  if (redirect) {
    return <Redirect to='/admin' />;
  }

  return offerToEdit != null ? (
    <div className='admin-edit'>
      <div class='container'>
        <div className='box'>
          <h2 className='text-accent'>Edytujesz ofertę {name}</h2>
          <form onSubmit={submit} encType='multipart/form-data'>
            <div className='field '>
              <div className='field-body'>
                <div className='field '>
                  <label className='label'>Tytuł</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='name'
                      onChange={(e) => onChange(e)}
                      value={name}
                    />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Adres url</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='url'
                      onChange={(e) => onChange(e)}
                      value={url}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field '>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>Url do mapy</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='map'
                      onChange={(e) => onChange(e)}
                      value={map}
                    />
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Cena</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      placeholder='Url'
                      name='price'
                      onChange={(e) => onChange(e)}
                      value={price}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <div className='field-body'>
                <div class='field'>
                  <div class='control'>
                    <label class='checkbox'>
                      <input
                        type='checkbox'
                        className=''
                        name='sold'
                        onChange={(e) => check(e)}
                        checked={sold}
                      />
                      Sprzedane
                    </label>
                  </div>
                </div>
                <div className='field'>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='number'
                        className=''
                        name='order'
                        onChange={(e) => onChange(e)}
                        value={order}
                      />
                      Kolejność
                    </label>
                  </div>
                </div>
                <div class='field'>
                  <div class='control'>
                    <label class='checkbox'>
                      <input
                        type='checkbox'
                        className=''
                        name='publish'
                        onChange={(e) => check(e)}
                        checked={publish}
                      />
                      Opublikuj
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class='field'>
              <label className='label'>Opis ogłoszenia</label>
              <div class='control'>
                <textarea
                  class='textarea textarea--admin is-primary'
                  placeholder=''
                  name='description'
                  id='description'
                  onChange={(e) => addDescription(e)}
                  value={areaValue}
                ></textarea>
              </div>
            </div>
            <div className='columns'>
              <div className='column is-6'>
                <label className='label'>Dodatkowe informacje</label>
                {Utils.mapObject(additionalInfo, (key, value) => {
                  return (
                    <div className='field'>
                      <div className='field-body'>
                        <div className='field'>
                          <div class='control'>
                            <input
                              className='input is-small'
                              type='text'
                              name='additionalInfo-name'
                              placeholder='Nazwa'
                              onChange={(e) => onChangeInfo(e)}
                              value={key}
                            />
                          </div>
                        </div>
                        <div className='field'>
                          <div class='control'>
                            <input
                              className='input is-small'
                              type='text'
                              name='additionalInfo-value'
                              placeholder=''
                              onChange={(e) => onChangeInfo(e)}
                              value={value}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <button onClick={(e) => addNewInput(e)}>Dodatkowe pole</button>
              </div>
              <div className='column is-6'>
                <div className='field'>
                  <label className='label'>Dodaj główne zdjęcie</label>
                  <div className='control'>
                    <input
                      className=''
                      type='file'
                      name='image'
                      id='thumbnail'
                      onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                </div>
                {thumbnail && (
                  <img
                    src={thumbnail}
                    id='img-thumbnail'
                    style={{ maxHeight: '400px' }}
                    alt='thumbnail'
                  />
                )}
              </div>
            </div>
            <div class='columns'>
              <div class='column'>
                <div className='field'>
                  <label className='label'>Dodaj zdjęcia</label>
                  <div className='control'>
                    <input
                      className=''
                      type='file'
                      multiple
                      name='image'
                      id='file'
                      onChange={(e) => handleImages(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='columns is-multiline'>
              {offerToEdit.photos &&
                offerToEdit.photos.map((photo, index) => {
                  return (
                    <div className='column is-3'>
                      <div style={{ display: 'flex', maxHeight: '220px' }}>
                        <img src={photo} alt={'photo-' + index}></img>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className='columns is-multiline'>
              {path.map((img, index) => {
                return (
                  <div className='column is-3'>
                    <div
                      className='toDelete'
                      onClick={(e) => deleteImage(e)}
                      data-path={img.original}
                    >
                      <img
                        style={{ maxHeight: '250px' }}
                        src={img.original}
                        alt={'photo-' + index}
                      />
                      <button className='button is-warning is-light'>
                        Usuń
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='field is-grouped is-grouped-right'>
              <p className='control'>
                {!loader ? (
                  <button
                    type='submit'
                    value='Edytuj'
                    className='button is-primary'
                  >
                    Edytuj
                  </button>
                ) : (
                  <button
                    value='Edytuj'
                    className='button is-primary is-loading'
                  >
                    Edytuj
                  </button>
                )}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div>loading</div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  offer: state.offer.offer,
  id: ownProps.match.params.id,
});

const mapDispatchToProps = {
  getOffer,
  postEditOffer,
  postThumbnail,
  setAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOffer);
