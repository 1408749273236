import React, { useState } from 'react';
import { connect } from 'react-redux';
import { postOffer, postThumbnail } from '../../../actions/offer';
import { setAlert } from '../../../actions/alert';
import { Redirect } from 'react-router-dom';
import * as Utils from '../../../utilities/Utils';
import './createOffer.scss';

const CreateOffer = ({ postOffer, postThumbnail, setAlert }) => {
  const [formData, setFormData] = useState({
    name: '',
    url: '',
    map: '',
    price: '',
    sold: false,
    publish: false,
    description: [],
    order: 0,
    additionalInfo: {
      Lokalizacja: '',
      Powierzchnia: '',
      Rynek: '',
      'Liczba Pokoi': '',
      'Rodzaj zabudowy': '',
      Piętro: '',
      'Liczba pięter': '',
      'Materiał budynku': '',
      Okna: '',
      'Rok budowy': '',
      'Stan wykończenia': '',
      Ogrzewanie: '',
      Czynsz: '',
      'Forma własności': '',
      Dostępność: '',
    },
    validation: {
      isSubmited: false,
      formIsValid: false,
      name: {
        value: '',
        error: ['pole jest wymagane'],
        controlerIsValid: false,
        validationRule: {
          isRequired: true,
        },
      },
      url: {
        value: '',
        error: ['pole jest wymagane'],
        controlerIsValid: false,
        validationRule: {
          isRequired: true,
        },
      },
      map: {
        value: '',
        error: ['pole jest wymagane'],
        controlerIsValid: false,
        validationRule: {
          isRequired: true,
        },
      },
      price: {
        value: '',
        error: ['pole jest wymagane'],
        controlerIsValid: false,
        validationRule: {
          isRequired: true,
        },
      },
      order: {
        value: 0,
        error: ['pole jest wymagane'],
        controlerIsValid: false,
        validationRule: {
          isRequired: true,
        },
      },
      thumbnail: {
        value: 0,
        error: ['zdjęcie jest wymagane'],
        controlerIsValid: false,
        validationRule: {
          fileExists: true,
        },
      },
      photos: {
        value: 0,
        error: ['zdjęcie jest wymagane'],
        controlerIsValid: false,
        validationRule: {
          fileExists: true,
        },
      },
    },
  });

  const formValidator = (value, rules) => {
    let isValid = {};
    let error = [];
    let controlerIsValid = true;
    for (let rule in rules) {
      switch (rule) {
        case 'isRequired':
          isValid[rule] = requiredValidator(value);
          if (!isValid[rule]) error.push('pole jest wymagane');
          controlerIsValid = controlerIsValid && isValid[rule];
          break;
        case 'fileExists':
          isValid[rule] = fileExistsChecker(value);
          if (!isValid[rule]) error.push('zdjęcie jest wymagane');
          controlerIsValid = controlerIsValid && isValid[rule];
          break;
        default:
          controlerIsValid = true;
          break;
      }
    }
    return [error, controlerIsValid];
  };

  const requiredValidator = (value) => {
    return value.trim() !== '';
  };

  const fileExistsChecker = (value) => {
    debugger;
    return value > 0;
  };

  const [loader, setLoader] = useState(false);

  const [redirect, setRedirect] = useState(false);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const updateFormControlers = {
      ...formData.validation,
    };

    const updateControler = {
      ...updateFormControlers[name],
    };

    var validation = formValidator(
      value,
      formData.validation[name].validationRule
    );

    updateFormControlers[name] = updateControler;

    updateControler.value = value;
    updateControler.error = validation[0];
    updateControler.controlerIsValid = validation[1];

    updateFormControlers.formIsValid =
      updateFormControlers.name.controlerIsValid &&
      updateFormControlers.url.controlerIsValid &&
      updateFormControlers.map.controlerIsValid &&
      updateFormControlers.price.controlerIsValid &&
      updateFormControlers.thumbnail.controlerIsValid &&
      updateFormControlers.photos.controlerIsValid;
    debugger;

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      validation: updateFormControlers,
    });
    debugger;
  };

  // const onChangeTextArea = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const check = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setFormData({
      ...formData,
      validation: { ...formData.validation, isSubmited: true },
    });

    if (formData.validation.formIsValid) {
      setLoader(true);
      const file = document.getElementById('file');
      const thumbnail = document.getElementById('thumbnail');
      const bodyWithImage = new FormData();
      bodyWithImage.append('name', formData.name);
      bodyWithImage.append('url', formData.url);
      bodyWithImage.append('price', formData.price);
      bodyWithImage.append('sold', formData.sold);
      bodyWithImage.append('map', formData.map);
      bodyWithImage.append('order', formData.order);
      bodyWithImage.append('publish', formData.publish);
      bodyWithImage.append(
        'additionalInfo',
        JSON.stringify(formData.additionalInfo)
      );
      bodyWithImage.append('description', JSON.stringify(formData.description));
      for (var i = 0; i < file.files.length; i++) {
        bodyWithImage.append('image', file.files[i]);
      }
      try {
        const offerResponse = await postOffer(bodyWithImage);
        const thumbnailData = new FormData();
        thumbnailData.append('id', offerResponse.data);
        thumbnailData.append('name', formData.name);
        thumbnailData.append('url', formData.url);
        thumbnailData.append('thumbnail', thumbnail.files[0]);
        if (offerResponse.data) {
          const thumbnailResposnse = await postThumbnail(thumbnailData);
          if (
            offerResponse.status === 200 &&
            thumbnailResposnse.status === 200
          ) {
            setRedirect(true);
            setAlert('Oferta dodana', 'success');
          }
        }
      } catch (error) {}
    }
    setLoader(false);
  };

  const onChangeInfo = (e) => {
    const names = [...document.querySelectorAll('[name=additionalInfo-name]')];
    const values = [
      ...document.querySelectorAll('[name=additionalInfo-value]'),
    ];
    const result = {};
    names.forEach((key, i) => (result[key.value] = values[i].value));
    setFormData({ ...formData, additionalInfo: result });
  };

  const addDescription = (e) => {
    e.preventDefault();
    var desc = document.getElementById('description').value;
    const textArray = desc.split('\n').filter((text) => {
      return text !== '';
    });
    setFormData({ ...formData, description: textArray });
  };

  const addNewInput = (e) => {
    e.preventDefault();
    const emptyObj = {
      '': '',
    };
    const newObj = Object.assign({}, formData.additionalInfo, emptyObj);

    setFormData({ ...formData, additionalInfo: newObj });
  };

  const addThumbnail = (e) => {
    const updateFormControlers = {
      ...formData.validation,
    };

    const updateControler = {
      ...updateFormControlers['thumbnail'],
    };

    var validation = formValidator(
      e.target.files.length,
      formData.validation['thumbnail'].validationRule
    );

    updateFormControlers['thumbnail'] = updateControler;

    updateControler.value = e.target.files.length;
    updateControler.error = validation[0];
    updateControler.controlerIsValid = validation[1];

    updateFormControlers.formIsValid =
      updateFormControlers.name.controlerIsValid &&
      updateFormControlers.url.controlerIsValid &&
      updateFormControlers.map.controlerIsValid &&
      updateFormControlers.price.controlerIsValid &&
      updateFormControlers.thumbnail.controlerIsValid &&
      updateFormControlers.photos.controlerIsValid;

    setFormData({
      ...formData,
      validation: updateFormControlers,
      thumbnail: URL.createObjectURL(e.target.files[0]),
    });
  };

  const handleImages = (e) => {
    const files = [...e.currentTarget.files];
    var photos = files.map((item) => {
      return URL.createObjectURL(item);
    });

    const updateFormControlers = {
      ...formData.validation,
    };

    const updateControler = {
      ...updateFormControlers['photos'],
    };

    var validation = formValidator(
      e.target.files.length,
      formData.validation['photos'].validationRule
    );

    updateFormControlers['photos'] = updateControler;

    updateControler.value = e.target.files.length;
    updateControler.error = validation[0];
    updateControler.controlerIsValid = validation[1];

    updateFormControlers.formIsValid =
      updateFormControlers.name.controlerIsValid &&
      updateFormControlers.url.controlerIsValid &&
      updateFormControlers.map.controlerIsValid &&
      updateFormControlers.price.controlerIsValid &&
      updateFormControlers.thumbnail.controlerIsValid &&
      updateFormControlers.photos.controlerIsValid;

    setFormData({
      ...formData,
      validation: updateFormControlers,
      photos: photos,
    });
  };

  const { name, url, sold, map, price, publish } = formData;

  if (redirect) {
    return <Redirect to='/admin' />;
  }

  return (
    <div className='admin-create'>
      <div className='container'>
        <div className='box'>
          <h2 className='text-accent'>Dodaj nową ofertę</h2>
          <form onSubmit={onSubmit} encType='multipart/form-data'>
            <div className='field '>
              <div className='field-body'>
                <div className='field '>
                  <label className='label'>Tytuł</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='name'
                      onChange={(e) => onChange(e)}
                      value={name}
                    />
                    {formData.validation.isSubmited &&
                      !formData.validation.name.controlerIsValid && (
                        <div className='offer-form__error'>
                          {formData.validation.name.error[0]}
                        </div>
                      )}
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Adres url</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='url'
                      onChange={(e) => onChange(e)}
                      value={url}
                    />
                    {formData.validation.isSubmited &&
                      !formData.validation.url.controlerIsValid && (
                        <div className='offer-form__error'>
                          {formData.validation.url.error[0]}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className='field '>
              <div className='field-body'>
                <div className='field'>
                  <label className='label'>Url do mapy</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      name='map'
                      onChange={(e) => onChange(e)}
                      value={map}
                    />
                    {formData.validation.isSubmited &&
                      !formData.validation.map.controlerIsValid && (
                        <div className='offer-form__error'>
                          {formData.validation.map.error[0]}
                        </div>
                      )}
                  </div>
                </div>
                <div className='field'>
                  <label className='label'>Cena</label>
                  <div className='control'>
                    <input
                      className='input'
                      type='text'
                      placeholder='Url'
                      name='price'
                      onChange={(e) => onChange(e)}
                      value={price}
                    />
                    {formData.validation.isSubmited &&
                      !formData.validation.price.controlerIsValid && (
                        <div className='offer-form__error'>
                          {formData.validation.price.error[0]}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        className=''
                        name='sold'
                        onChange={(e) => check(e)}
                        checked={sold}
                      />
                      Sprzedane
                    </label>
                  </div>
                </div>
                <div className='field'>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='number'
                        className=''
                        name='order'
                        onChange={(e) => onChange(e)}
                      />
                      Kolejność
                    </label>
                  </div>
                </div>
                <div className='field'>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        className=''
                        name='publish'
                        onChange={(e) => check(e)}
                        checked={publish}
                      />
                      Opublikuj
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='label'>Opis ogłoszenia</label>
              <div className='control'>
                <textarea
                  className='textarea textarea--admin is-primary'
                  placeholder=''
                  name='description'
                  id='description'
                  onChange={(e) => addDescription(e)}
                ></textarea>
              </div>
            </div>
            <div className='columns'>
              <div className='column is-6'>
                <label className='label'>Dodatkowe informacje</label>
                {Utils.mapObject(formData.additionalInfo, (key, value) => {
                  return (
                    <div className='field'>
                      <div className='field-body'>
                        <div className='field'>
                          <div className='control'>
                            <input
                              className='input is-small'
                              type='text'
                              name='additionalInfo-name'
                              placeholder='Nazwa'
                              onChange={(e) => onChangeInfo(e)}
                              value={key}
                            />
                          </div>
                        </div>
                        <div className='field'>
                          <div className='control'>
                            <input
                              className='input is-small'
                              type='text'
                              name='additionalInfo-value'
                              placeholder=''
                              onChange={(e) => onChangeInfo(e)}
                              value={value}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <button onClick={(e) => addNewInput(e)}>Dodatkowe pole</button>
              </div>
              <div className='column is-6'>
                <div className='field'>
                  <label className='label'>Dodaj główne zdjęcie</label>
                  <div className='control'>
                    <input
                      className=''
                      type='file'
                      name='image'
                      id='thumbnail'
                      onChange={(e) => addThumbnail(e)}
                    />
                    {formData.validation.isSubmited &&
                      !formData.validation.thumbnail.controlerIsValid && (
                        <div className='offer-form__error'>
                          {formData.validation.thumbnail.error[0]}
                        </div>
                      )}
                  </div>
                </div>
                {formData.thumbnail && (
                  <img
                    src={formData.thumbnail}
                    id='img-thumbnail'
                    style={{ maxHeight: '400px' }}
                    alt='thumbnail'
                  />
                )}
              </div>
            </div>
            <div className='columns'>
              <div className='column'>
                <div className='field'>
                  <label className='label'>Dodaj zdjęcia</label>
                  <div className='control'>
                    <input
                      className=''
                      type='file'
                      multiple
                      name='image'
                      id='file'
                      onChange={(e) => handleImages(e)}
                    />
                    {formData.validation.isSubmited &&
                      !formData.validation.photos.controlerIsValid && (
                        <div className='offer-form__error'>
                          {formData.validation.photos.error[0]}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className='columns is-multiline'>
              {formData.photos &&
                formData.photos.map((photo, index) => {
                  return (
                    <div className='column is-3'>
                      <div style={{ display: 'flex', maxHeight: '220px' }}>
                        <img src={photo} alt={index}></img>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className='field is-grouped is-grouped-right'>
              <p className='control'>
                {!loader ? (
                  <button
                    type='submit'
                    value='Dodaj'
                    className='button is-primary'
                  >
                    Dodaj
                  </button>
                ) : (
                  <button
                    value='Dodaj'
                    className='button is-primary is-loading'
                  >
                    Dodaj
                  </button>
                )}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { postOffer, postThumbnail, setAlert })(
  CreateOffer
);
