import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({ offer }) => {
  const { thumbnail, price, name, _id, additionalInfo, url } = offer;

  return (
    <div className='column is-half-tablet is-one-third-desktop'>
      {offer && (
        <Link to={`/oferta/${url}`}>
          <div className='offer-item'>
            <img src={thumbnail} alt={name} />
            <div className='offer-item__description'>
              <div>
                {offer.sold ? (
                  <span className='offer-item__text-price'>Sprzedane</span>
                ) : (
                  <span className='offer-item__text-price'>{price}</span>
                )}
              </div>
              <div>
                <span>{name} </span>
              </div>
              <div>{additionalInfo[0].Powierzchnia}</div>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default Item;
