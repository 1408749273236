import React from 'react';
import { Link } from 'react-router-dom';
import './getOffer.scss';

const GetOffer = () => {
  return (
    <div className='container'>
      <div className='get-offer'>
        <div className='get-offer__content'>
          <div className='get-offer__yellow-bg'>
            <h1 className='font--grey is-hidden-tablet'>
              SPRZEDAJ NAM SWOJĄ NIERUCHOMOŚĆ 0% PROWIZJI I OPŁAT!
            </h1>
            <h1 className='font--grey is-hidden-mobile'>
              SPRZEDAJ NAM SWOJĄ <br />
              NIERUCHOMOŚĆ <br />
              0% PROWIZJI I OPŁAT!
            </h1>
            <div className='get-offer__font-position '>
              <p className='get-offer__text font-medium font--grey'>
                Wypełnij formularz bądź skontaktuj się z jednym z Naszych
                agentów aby uzyskać ofertę!
              </p>
              <p className='get-offer__text font-medium font--grey'>
                Gwarantujemy szybką oraz bezpieczną transakcję.
              </p>
              <p className='get-offer__text font-medium font--grey'>
                Bez zbędnych stresów oraz formalności.
              </p>
              <div className='get-offer__button-wrapper'>
                <Link
                  to={'/ankieta'}
                  className='button button--main button--dark-background'
                >
                  Uzyskaj Ofertę
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='get-offer__contact'>
        <p className='get-offer__contact-text font-medium font--white'>
          Zapraszam do kontaktu:
        </p>
        <p className='get-offer__contact-number h2-like font--white'>
          +48 575 050 898
        </p>
      </div>
    </div>
  );
};

export default GetOffer;
