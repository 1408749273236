export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCES';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFER = 'GET_OFFER';
export const GET_ACTIVE_OFFERS = 'GET_ACTIVE_OFFERS';
export const GET_SOLD_OFFERS = 'GET_SOLD_OFFERS';
export const GET_ACTIVE_PUBLISHED_OFFERS = 'GET_ACTIVE_PUBLISHED_OFFERS';
export const GET_SOLD_PUBLISHED_OFFERS = 'GET_SOLD_PUBLISHED_OFFERS';
