import React from 'react';
import './benefits.scss';

const Benefits = () => {
  return (
    <div id='benefits' className='container'>
      <h2 className='title-margin title-accent'>
        Co zyskasz dzięki sprzedaży Housellin?
      </h2>
      <div className='columns is-multiline'>
        <div className='column is-full-tablet is-one-third-desktop'>
          <div className='benefit__description'>
            <img src='/assets/time.png' className='benefit__image' alt='time' />
            <div>
              <span className='benefit__title'>Czas</span>
              <span className='benefit__accent-line'></span>
              Czas Finalizacja transakcji sprzedaży mieszkania może się ciągnąć
              miesiącami. <br />
              <br />Z jednej strony znalezienie klienta jest pracochłonne, a z
              drugiej większość nabywców kupuje na kredyt, którego procedury
              formalne również zajmują czas!
            </div>
            <div className='clearfix'></div>
          </div>
        </div>
        <div className='column is-full-tablet is-one-third-desktop'>
          <div className='benefit__description'>
            <img
              src='/assets/dollar.png'
              className='benefit__image'
              alt='dollar'
            />
            <div className='benefit__title'>Cena</div>
            <span className='benefit__accent-line'></span>
            <span>
              Oferta którą od nas uzyskasz będzie oparta na aktualnych cenach
              transakcyjnych nieruchomości z Twojej okolicy. <br /> <br />
              Dzięki czemu masz gwarancję uczciwej wyceny swojej nieruchomości.
            </span>
            <div className='clearfix'></div>
          </div>
        </div>
        <div className='column is-full-tablet is-one-third-desktop'>
          <div className='benefit__description'>
            <img
              src='/assets/kalendar.png'
              className='benefit__image'
              alt='kalendar'
            />
            <div className='benefit__title'>Spokój i wygoda</div>
            <span className='benefit__accent-line'></span>
            <span>
              Unikniesz licznych prezentacji nieruchomości, kontaktów z
              nachalnymi pośrednikami oraz uciążliwymi klientami. <br /> <br />Z
              nami uda Ci się to zrobić nawet w 7 dni!
            </span>
            <div className='clearfix'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
