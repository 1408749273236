import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_OFFERS,
  GET_OFFER,
  GET_ACTIVE_OFFERS,
  GET_SOLD_OFFERS,
  GET_ACTIVE_PUBLISHED_OFFERS,
  GET_SOLD_PUBLISHED_OFFERS,
} from './types';

export const getOffers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/offer');
    const activeOffers = res.data.filter((item) => {
      return !item.sold;
    });

    const soldOffers = res.data.filter((item) => {
      return item.sold;
    });

    const activePublishedOffers = res.data.filter((item) => {
      if (!item.sold && item.publish) return item;
    });

    const soldPublishedOffers = res.data.filter((item) => {
      if (item.sold && item.publish) return item;
    });

    dispatch({
      type: GET_OFFERS,
      payload: res.data,
    });

    dispatch({
      type: GET_ACTIVE_OFFERS,
      payload: activeOffers,
    });

    dispatch({
      type: GET_SOLD_OFFERS,
      payload: soldOffers,
    });

    dispatch({
      type: GET_SOLD_PUBLISHED_OFFERS,
      payload: soldPublishedOffers,
    });

    dispatch({
      type: GET_ACTIVE_PUBLISHED_OFFERS,
      payload: activePublishedOffers,
    });
  } catch (error) {
    dispatch(setAlert(error.response.data, 'danger'));
  }
};

export const getOffer = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/offer/${id}`);
    dispatch({
      type: GET_OFFER,
      payload: res.data,
    });
    return res;
  } catch (error) {
    dispatch(setAlert('Oferty nieznaleziono', 'danger'));
    return error.response;
  }
};

export const getOfferByUrl = (url) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/offer/getOfferByUrl/${url}`);
    debugger;
    console.log(res);
    dispatch({
      type: GET_OFFER,
      payload: res.data,
    });
    return res;
  } catch (error) {
    debugger;
    dispatch(setAlert('Oferty nieznaleziono', 'danger'));
    return error.response;
  }
};

export const postOffer = (offer) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const res = await axios.post('/api/offer', offer, config);
    return res;
  } catch (error) {
    dispatch(setAlert(error.response.data, 'danger'));
  }
};

export const postThumbnail = (thumbnail) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    debugger;
    const res = await axios.post('/api/offer/thumbnail', thumbnail, config);
    return res;
  } catch (error) {
    dispatch(setAlert(error.response.data, 'danger'));
  }
};

export const postEditOffer = (data) => async (dispatch) => {
  try {
    const res = await axios.post('/api/offer/editOffer', data);
    // dispatch({
    //   type: GET_OFFER,
    //   payload: res.data,
    // });
    return res;
  } catch (error) {
    dispatch(setAlert(error.response.data, 'danger'));
  }
};

export const deleteOffer = (id) => async (dispatch) => {
  console.log(id);
  try {
    const res = await axios.delete('/api/offer/deleteOffer', {
      data: { id: id },
    });
    return res;
  } catch (error) {
    dispatch(setAlert(error.response.data, 'danger'));
  }
};
