import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './alert.scss';

const Alert = ({ alerts }) => {
  return (
    <div className='container alert'>
      {alerts !== null &&
        alerts.length > 0 &&
        alerts.map(alert => (
          <div className={`message is-${alert.type}`}>
            <div className='message-body'>{alert.msg}</div>
          </div>
        ))}
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
