import React, { Component } from 'react';
import './survey.scss';
import { Helmet } from 'react-helmet';

class Survey extends Component {
  componentDidMount() {
    this.sizeDiv();
  }

  sizeDiv = () => {
    var advInner = document.querySelector('.freebirdFormviewerViewEmbedded');
    let rec;
    if (advInner != null) {
      rec = advInner.getBoundingClientRect();
      var div = document.querySelector('.advantages-bg');
      var heightt = rec.height;
      var width = rec.width;
      div.style.borderWidth = `0 0 ${heightt + 116}px ${width}px`;
    }
  };

  render() {
    return (
      <div className='container survey'>
        <iframe
          src='https://docs.google.com/forms/d/e/1FAIpQLSfVmGBb7SlhIQVIxnjvy-lGpFJIZHuVT-cBRKZOzue7C1jN5Q/viewform?embedded=true'
          width='640'
          height='2000'
          frameBorder='0'
          marginHeight='0'
          marginWidth='0'
          title='survey'
        >
          Ładuję…
        </iframe>
      </div>
    );
  }
}

// <div className='container'>
//   <div className='survey'>
//     <p class='survey__text'>Wypełnij krótki formularz i uzyskaj ofertę</p>
//   </div>
//   <div>
//     <h2 className='title-margin title-accent '>Formularz</h2>
//   </div>
//   <form class='ankieta' name='contact' netlify>
//     <div className='field'>
//       <label class='label'>Podaj adres mieszkania:</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Jaką powierzchnię ma mieszkanie?</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Ile pokoi ma mieszkanie?</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Udogodnienia mieszkania:</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Jak oceniasz standard mieszkania?</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>
//         Kiedy przeprowadzany był ostatni remont generalny w mieszkaniu?
//       </label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>
//         Na którym piętrze znajduje się mieszkanie?
//       </label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Ile pięter ma budynek?</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Z którego roku pochodzi budynek?</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Czy jesteś właścicielem tego mieszkania?</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Jaki jest stan prawny mieszkania?</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>
//         Czy mieszkanie ma wyodrębnioną księgę wieczystą?
//       </label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>
//         W jakiej perspektywie czasowej chciałbyś/chciałabyś sprzedać to
//         mieszkanie?
//       </label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>
//         Podaj proszę kwotę, za którą chciałbyś/chciałabyś sprzedać
//         mieszkanie:
//       </label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>Imię i Naziwsko</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <div className='field'>
//       <label class='label'>E-mail na który zostanie wysłana oferta</label>
//       <div className='control'>
//         <input className='input' type='text' name='imie_i_nazwisko' />
//       </div>
//     </div>
//     <button className='button' type='submit'>
//       Send
//     </button>
//   </form>
// </div>

export default Survey;
