import React, { Component } from 'react';

const Description = ({ description, classStyle }) => {
  return (
    <div className={classStyle}>
      <div className='offer-description__title'>
        <span className='h2-like title-accent'>Opis ogłoszenia</span>
      </div>
      {description.map((txt, index) => {
        return (
          <p key={index} className='offer-description__text'>
            {txt}
          </p>
        );
      })}
    </div>
  );
};

export default Description;
