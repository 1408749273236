import React, { Fragment } from 'react';
import './navbar.scss';
import logo from '../../../../src/assets/housellin_logo.png';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';

const Navbar = ({ isAuthenticated, logout }) => {
  const toogleMenuMobile = () => {
    document.querySelector('.navbar-menu').classList.toggle('is-active');
    document.querySelector('.navbar-burger').classList.toggle('is-active');
  };

  function hideMenuAfterClick() {
    var x = document.getElementById('menu');
    if (x.classList.contains('is-active')) {
      document.querySelector('.navbar-menu').classList.remove('is-active');
      document.querySelector('.navbar-burger').classList.remove('is-active');
    }
  }

  const authLinks = (
    <Fragment>
      <Link
        className='navbar-item'
        to='/admin'
        onClick={() => {
          hideMenuAfterClick();
        }}
      >
        admin
      </Link>
      <a className='navbar-item' onClick={() => logout()} href='/'>
        logout
      </a>
    </Fragment>
  );

  return (
    <div className='navbar-wrapper'>
      <nav className='navbar' role='navigation' aria-label='main navigation'>
        <div className='container'>
          <div className='navbar-brand'>
            <Link
              className='navbar-item'
              to='/'
              onClick={() => {
                hideMenuAfterClick();
              }}
            >
              <img src={logo} alt='logo' />
            </Link>

            <button
              className='navbar-burger'
              aria-label='menu'
              aria-expanded='false'
              onClick={toogleMenuMobile}
            >
              <span aria-hidden='true' />
              <span aria-hidden='true' />
              <span aria-hidden='true' />
            </button>
          </div>
          <div id='menu' className='navbar-menu'>
            <Link
              className='navbar-item'
              to='/#nasze-nieruchomosci'
              onClick={() => {
                hideMenuAfterClick();
              }}
            >
              Nasze nieruchomości
            </Link>
            <Link
              className='navbar-item'
              to='/#benefits'
              onClick={() => {
                hideMenuAfterClick();
              }}
            >
              Jakie są korzyści
            </Link>
            <a
              className='navbar-item'
              href='/#hiw'
              onClick={() => {
                hideMenuAfterClick();
              }}
            >
              Jak to działa
            </a>
            <Link
              className='navbar-item'
              to='/#advantages'
              onClick={() => {
                hideMenuAfterClick();
              }}
            >
              Co nas wyróżnia
            </Link>
            <Link
              className='navbar-item'
              to='/#faq'
              onClick={() => {
                hideMenuAfterClick();
              }}
            >
              FAQ
            </Link>
            <Link
              className='navbar-item'
              to='/o-nas'
              onClick={() => {
                hideMenuAfterClick();
              }}
            >
              O nas
            </Link>
            <Link
              className='navbar-item'
              to='/o-nas#contact'
              onClick={() => {
                hideMenuAfterClick();
              }}
            >
              Kontakt
            </Link>
            {isAuthenticated && authLinks}
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Navbar);
