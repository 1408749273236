export const faqData = [
  {
    question:
      'Czy poniosę dodatkowe koszty związane z transakcja sprzedaży do Housellin?',
    answer:
      'Nie, koszty związane z obsługą transakcji leżą po stronie Housellin. Nie ma żadnych dodatkowych prowizji i opłat.'
  },
  {
    question:
      'Czy mogę się zgłosić z mieszkaniem posiadającym obciążenie hipoteczne bądź niejasna sytuacje prawną?',
    answer:
      'Oczywiście! Bezpłatnie przeprowadzimy analizę sytuacji prawnej mieszkania i sprawdzimy możliwość sprzedaży z bieżącym obciążeniem hipotecznym.'
  },
  {
    question: 'Ile czasu trwa proces sprzedaży?',
    answer:
      'Z uwagi na fakt, iż dysponujemy środkami własnymi i płacimy gotówką jesteśmy w stanie sfinalizować sprzedaż nawet w 7 dni.'
  },
  {
    question: 'Czy muszę przygotowywać mieszkanie do sprzedaży Housellin?',
    answer:
      'Nie, cała przyjemność po naszej stronie, żeby Cię w tym wyręczyć. Szanujemy Twój czas, dlatego to my zrobimy to wszystko za Ciebie.'
  },
  {
    question: 'Kiedy mogę spodziewać się wizyty agenta?',
    answer:
      'Najszybciej jak jest to możliwe. Nasi agenci dostępni są do Państwa dyspozycji od wczesnych godzin porannych do późnych popołudniowych. Jednak to sprzedający ustala dogodny dla siebie termin, kiedy chciałby się z nami spotkać.'
  },
  {
    question: 'Czy ponoszę jakieś koszty związane z uzyskaniem oferty?',
    answer: 'Nie pobieramy opłat za przygotowanie oferty.'
  },
  {
    question: 'Czy wysłanie formularza ofertowego jest w jakiś sposób wiążące?',
    answer:
      'Nie, sama oferta jak i wizyta agenta nie są w żaden sposób wiążące. Tylko od Ciebie zależy czy zaakceptujesz warunki i zdecydujesz się Nam sprzedać swoją nieruchomość.'
  },
  {
    question: 'Jakie informacje muszę podać w formularzu?',
    answer:
      'W formularzu prosimy o  dostarczenie nam informacji niezbędnych do właściwej wyceny mieszkania, czyli: metraż, standard wykończenia, liczba pokoi, dodatkowe zalety czy stan prawny.'
  },
  {
    question:
      'Czy zdjęcie mojego mieszkania i moje dane kontaktowe są w jakiś sposób przetwarzane?',
    answer:
      'Zdjęcia i dane kontaktowe nie są udostępniane. Wykorzystujemy je jedynie do przygotowania oferty dla wysłanego zgłoszenia. Z polityką prywatności Housellin, można się zapoznać na stronie: <a href="/polityka-prywatnosci">Polityka prywatności.</a>'
  }
];
