import React, { useEffect } from 'react';
import './advantages.scss';

const Advantages = () => {
  useEffect(() => {
    sizeDiv();
  }, []);

  const sizeDiv = () => {
    var advInner = document.querySelector('.advantages__content');
    let rec;
    if (advInner != null) {
      rec = advInner.getBoundingClientRect();
      var div = document.querySelector('.advantages__background');
      var heightt = rec.height;
      var width = rec.width;
      div.style.borderWidth = `0 0 ${heightt + 116 + 112}px ${width}px`;
    }
  };

  window.addEventListener('resize', function () {
    sizeDiv();
  });

  return (
    <section
      id='advantages'
      className='container advantages margin-section--advantages'
    >
      <div className='advantages__background'></div>
      <div className='advantages__content'>
        <h2 className='title-margin title-accent'>
          Co wyróżnia sprzedaż do Housellin?
        </h2>
        <div className='columns'>
          <div className='column'>
            <div className='advantages__item'>
              <div className='font-medium has-text-centered'>
                Sprzedaż do Housellin
              </div>
              <div className='advantages__description-wrapper'>
                <i className='fas fa-check-circle'></i>
                <div className='check-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Skrócony czas transakcji – nawet do 7 dni.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-check-circle'></i>
                <div className='check-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Unikniesz prowizji pośredników.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-check-circle'></i>
                <div className='check-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Rzetelna wycena.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-check-circle'></i>
                <div className='check-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Uczciwe warunki – brak ukrytych opłat i prowizji.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-check-circle'></i>
                <div className='check-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Nie musisz przygotowywać mieszkania na dziesiątki prezentacji.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-check-circle'></i>
                <div className='check-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Nie wydajesz pieniędzy na ogłoszenia.
                </div>
              </div>
            </div>
          </div>
          <div className='column'>
            <div className='advantages__item'>
              <div className='font-medium has-text-centered'>
                Sprzedaż samodzielna
              </div>
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Aktualnie średni czas sprzedaży mieszkania w naszym regionie
                  to 6 miesięcy.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Koszty pośrednika - ok 3-5% prowizji dla sprzedającego i
                  drugie tyle dla kupującego.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Przygotowywanie mieszkania do sprzedaży.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Dyskomfort związany z częstymi prezentacjami nieruchomości.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  W przypadku klienta kredytowego formalności mogą zająć nawet
                  do 60 dni. Pamiętaj, że nie ma gwarancji, że kredyt zostanie
                  przyznany.
                </div>
              </div>
            </div>
          </div>
          <div className='column'>
            <div className='advantages__item'>
              <div className='font-medium has-text-centered'>
                Sprzedaż z agentem
              </div>
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Agent przygotowuje ogłoszenie według subiektywnej wyceny.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Razem z agentem zajmujesz się obsługą zainteresowanych i
                  negocjacjami, czas sprzedaży zależy od efektywności agenta.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Pokrywasz dodatkowe koszty i prowizję agenta.
                </div>
              </div>
              <hr />
              <div className='advantages__description-wrapper'>
                <i className='fas fa-minus-circle'></i>
                <div className='minus-circle-background'></div>
                <div className='advantages__description font-smallest'>
                  Nie masz gwarancji, że sprzedaż dojdzie do skutku.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
