import React from 'react';
import './privacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <div className='container privacy-policy'>
      <div className='columns'>
        <div className='column is-8 is-offset-2'>
          <div className='privacy-policy__header'>
            <h1 className='h2-like'>Polityka prywatności Housellin</h1>
            <p>
              Dodanie danych osobowych, a także zgoda na ich przetwarzanie są
              całkowicie dobrowolne. Wszelkie przekazane nam dane osobowe są
              przetwarzane wyłącznie w zakresie i celu, na jaki wyraziłeś zgodę.
            </p>
            <p>
              Pamiętaj, że w każdej chwili masz prawo do aktualizacji lub
              całkowitego usunięcia danych osobowych. Możesz to zrobić
              samodzielnie lub z naszą pomocą, której Ci chętnie udzielimy.
            </p>
          </div>
          <h2>1. Informacje ogólne dotyczące danych osobowych.</h2>
          <ol>
            <li>
              Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod
              adresem url: https://housellin.pl
            </li>
            <li>
              Operatorem serwisu oraz Administratorem danych osobowych jest:
              HOUSELLIN spółka z ograniczoną odpowiedzialnością z siedzibą w
              Koszalinie, adres 75-712 Koszalin, ul. Wojska Polskiego nr 24-26,
              REGON 384008550, NIP 669-255-40-53, wpisanej do Rejestru
              Przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy w
              Koszalinie IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod
              numerem KRS 0000797549
            </li>
            <li>
              Adres kontaktowy poczty elektronicznej operatora:
              kontakt@housellin.pl
            </li>
            <li>
              Operator jest Administratorem Twoich danych osobowych w
              odniesieniu do danych podanych dobrowolnie w Serwisie
              www.housellin.pl, zwanym dalej “Serwisem.”
            </li>
            <li>
              Serwis wykorzystuje dane osobowe w następujących celach:
              <ol>
                <li>Przygotowywanie oferty zakupu nieruchomości</li>
                <li>Prowadzenie newslettera.</li>
              </ol>
            </li>
            <li>
              Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i
              ich zachowaniu w następujący sposób:
              <ol>
                <li>
                  Poprzez dobrowolnie wprowadzone w formularzach dane, które
                  zostają wprowadzone do systemów Operatora.
                </li>
                <li>
                  Poprzez zapisywanie w urządzeniach końcowych plików cookie
                  (tzw. „ciasteczka”).
                </li>
              </ol>
            </li>
          </ol>
          <h2>2.Hosting</h2>
          <ol>
            <li>
              Serwis jest hostowany (technicznie utrzymywany) na serwera
              operatora: OVH sp. z o.o. z siedzibą we Wrocławiu (KRS:
              0000220286; NIP: 8992520556),{' '}
              <a
                target='_blank'
                href='https://www.ovh.pl/pomoc/informacje-prawne/'
                rel='noopener noreferrer'
              >
                więcej informacji
              </a>
              .
            </li>
          </ol>
          <h2>
            3. Twoje prawa i dodatkowe informacje o sposobie wykorzystania
            danych
          </h2>
          <ol>
            <li>
              W niektórych sytuacjach Administrator ma prawo przekazywać Twoje
              dane osobowe innym odbiorcom, jeśli będzie to niezbędne do
              wykonania zawartej z Tobą umowy lub do zrealizowania obowiązków
              ciążących na Administratorze. Dotyczy to takich grup odbiorców:
              <ol>
                <li>firma hostingowa na zasadzie powierzenia</li>
                <li>agencja pośrednictwa w handlu nieruchomościami.</li>
                <li>agencja marketingowa</li>
              </ol>
            </li>
            <li>
              Twoje dane osobowe przetwarzane przez Administratora nie dłużej,
              niż jest to konieczne do wykonania związanych z nimi czynności
              określonych osobnymi przepisami (np. o prowadzeniu rachunkowości).
              W odniesieniu do danych marketingowych dane nie będą przetwarzane
              dłużej niż przez 3 lata.
            </li>
            <li>
              Przysługuje Ci prawo żądania od Administratora:
              <ol>
                <li>dostępu do danych osobowych Ciebie dotyczących,</li>
                <li>ich sprostowania,</li>
                <li>usunięcia</li>
                <li>ograniczenia przetwarzania,</li>
                <li>oraz przenoszenia danych.</li>
              </ol>
            </li>
            <li>
              Przysługuje Ci prawo do złożenia sprzeciwu w zakresie
              przetwarzania wskazanego w pkt 4.3 c) wobec przetwarzania danych
              osobowych w celu wykonania prawnie uzasadnionych interesów
              realizowanych przez Administratora, w tym profilowania, przy czym
              prawo sprzeciwu nie będzie mogło być wykonane w przypadku
              istnienia ważnych prawnie uzasadnionych podstaw do przetwarzania,
              nadrzędnych wobec Ciebie interesów, praw i wolności, w
              szczególności ustalenia, dochodzenia lub obrony roszczeń.
            </li>
            <li>
              Na działania Administratora przysługuje skarga do Prezesa Urzędu
              Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
            </li>
            <li>
              Podanie danych osobowych jest dobrowolne, lecz niezbędne do
              obsługi Serwisu.
            </li>
            <li>
              W stosunku do Ciebie mogą być podejmowane czynności polegające na
              zautomatyzowanym podejmowaniu decyzji, w tym profilowaniu w celu
              świadczenia usług w ramach zawartej umowy oraz w celu prowadzenia
              przez Administratora marketingu bezpośredniego.
            </li>
            <li>
              Dane osobowe nie są przekazywane od krajów trzecich w rozumieniu
              przepisów o ochronie danych osobowych. Oznacza to, że nie
              przesyłamy ich poza teren Unii Europejskiej.
            </li>
          </ol>
          <h2>4. Informacje w formularzach</h2>
          <ol>
            <li>
              Serwis zbiera informacje podane dobrowolnie przez użytkownika, w
              tym dane osobowe, o ile zostaną one podane.
            </li>
            <li>
              Serwis może zapisać informacje o parametrach połączenia
              (oznaczenie czasu, adres IP).
            </li>
            <li>
              Serwis, w niektórych wypadkach, może zapisać informację
              ułatwiającą powiązanie danych w formularzu z adresem e-mail
              użytkownika wypełniającego formularz. W takim wypadku adres e-mail
              użytkownika pojawia się wewnątrz adresu url strony zawierającej
              formularz.
            </li>
            <li>
              Dane podane w formularzu są przetwarzane w celu wynikającym z
              funkcji konkretnego formularza, np. w celu dokonania procesu
              obsługi zgłoszenia serwisowego lub kontaktu handlowego,
              rejestracji usług itp. Każdorazowo kontekst i opis formularza w
              czytelny sposób informuje, do czego on służy.
            </li>
          </ol>
          <h2>5. Logi Administratora</h2>
          <ol>
            <li>
              Informacje zachowaniu użytkowników w serwisie mogą podlegać
              logowaniu. Dane te są wykorzystywane w celu administrowania
              serwisem.
            </li>
          </ol>
          <h2>6. Istotne techniki marketingowe</h2>
          <ol>
            <li>
              Operator stosuje analizę statystyczną ruchu na stronie, poprzez
              Google Analytics (Google Inc. z siedzibą w USA). Operator nie
              przekazuje do operatora tej usługi danych osobowych, a jedynie
              zanonimizowane informacje. Usługa bazuje na wykorzystaniu
              ciasteczek w urządzeniu końcowym użytkownika. W zakresie
              informacji o preferencjach użytkownika gromadzonych przez sieć
              reklamową Google użytkownik może przeglądać i edytować informacje
              wynikające z plików cookies przy pomocy narzędzia:
              <a href='https://www.google.com/ads/preferences/'>
                https://www.google.com/ads/preferences/
              </a>
            </li>
            <li>
              Operator korzysta z piksela Facebooka. Ta technologia powoduje, że
              serwis Facebook (Facebook Inc. z siedzibą w USA) wie, że dana
              osoba w nim zarejestrowana korzysta z Serwisu. Bazuje w tym
              wypadku na danych, wobec których sam jest administratorem,
              Operator nie przekazuje od siebie żadnych dodatkowych danych
              osobowych serwisowi Facebook. Usługa bazuje na wykorzystaniu
              ciasteczek w urządzeniu końcowym użytkownika.
            </li>
          </ol>
          <h2>7. Informacja o plikach cookies</h2>
          <ol>
            <li>Serwis korzysta z plików cookies.</li>
            <li>
              Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
              szczególności pliki tekstowe, które przechowywane są w urządzeniu
              końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze
              stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę
              strony internetowej, z której pochodzą, czas przechowywania ich na
              urządzeniu końcowym oraz unikalny numer.
            </li>
            <li>
              Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
              Serwisu pliki cookies oraz uzyskującym do nich dostęp jest
              operator Serwisu.
            </li>
            <li>
              Pliki cookies wykorzystywane są w następujących celach:
              <ol>
                <li>
                  realizacji celów określonych powyżej w części „Istotne
                  techniki marketingowe”;
                </li>
              </ol>
            </li>
            <li>
              W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
              cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
              cookies). Cookies „sesyjne” są plikami tymczasowymi, które
              przechowywane są w urządzeniu końcowym Użytkownika do czasu
              wylogowania, opuszczenia strony internetowej lub wyłączenia
              oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies
              przechowywane są w urządzeniu końcowym Użytkownika przez czas
              określony w parametrach plików cookies lub do czasu ich usunięcia
              przez Użytkownika.
            </li>
            <li>
              Oprogramowanie do przeglądania stron internetowych (przeglądarka
              internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików
              cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu
              mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka
              internetowa umożliwia usunięcie plików cookies. Możliwe jest także
              automatyczne blokowanie plików cookies Szczegółowe informacje na
              ten temat zawiera pomoc lub dokumentacja przeglądarki
              internetowej.
            </li>
            <li>
              Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
              funkcjonalności dostępne na stronach internetowych Serwisu.
            </li>
            <li>
              Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
              Serwisu wykorzystywane mogą być również przez współpracujące z
              operatorem Serwisu podmioty, w szczególności dotyczy to firm:
              Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z
              siedzibą w USA).
            </li>
          </ol>
          <h2>
            8. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać
            zgodę?
          </h2>
          <ol>
            <li>
              Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić
              ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików
              cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
              utrzymania preferencji użytkownika może utrudnić, a w skrajnych
              przypadkach może uniemożliwić korzystanie ze stron www
            </li>
            <li>
              W celu zarządzania ustawienia cookies wybierz z listy poniżej
              przeglądarkę internetową, której używasz i postępuj zgodnie z
              instrukcjami:
              <ul>
                <li>
                  <a href='https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history'>
                    Edge
                  </a>
                </li>
                <li>
                  <a href='https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer'>
                    Internet Explorer
                  </a>
                </li>
                <li>
                  <a href='https://support.google.com/chrome/answer/95647?hl=pl'>
                    Chrome
                  </a>
                </li>
                <li>
                  <a href='https://support.apple.com/pl-pl/guide/safari/sfri11471/mac'>
                    Safari
                  </a>
                </li>
                <li>
                  <a href='https://support.mozilla.org/pl/kb/wlaczanie-i-wylaczanie-ciasteczek-sledzacych?redirectlocale=pl&redirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek'>
                    Firefox
                  </a>
                </li>
                <li>
                  <a href='https://help.opera.com/pl/latest/web-preferences/#cookies'>
                    Opera
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Urządzenia mobilne
              <ul>
                <li>
                  <a href='https://support.google.com/chrome/answer/95647?hl=pl'>
                    Android
                  </a>
                </li>
                <li>
                  <a href='https://support.apple.com/pl-pl/HT201265'>
                    Safari (iOS)
                  </a>
                </li>
                <li>
                  <a href='https://support.microsoft.com/pl-pl/help/11696/windows-phone-7'>
                    Windows Phone
                  </a>
                </li>
              </ul>
            </li>
          </ol>
          <h2>9. Subskrypcja newslettera.</h2>
          <p>
            W przypadku subskrypcji newslettera oraz wyrażenia zgody na jego
            otrzymywanie w procesie rejestracji lub składania zamówienia prosimy
            jedynie o podanie adresu e-mail, na który przesyłane będę informacje
            , z których w każdej chwili możesz zrezygnować klikając link
            anulujący subskrypcję i znajdujący się w stopce każdego newslettera.
          </p>
          <p>
            Dane podane w trakcie subskrypcji newslettera są przetwarzane przez
            Serwis oraz następujące podmioty:
          </p>
          <p>
            Mailchimp mailing platform, The Rocket Science Group, LLC 675 Ponce
            de Leon Ave NE Suite 5000 Atlanta, GA 30308 USA, link do{' '}
            <a href='https://mailchimp.com/legal/'>polityki prywatności</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
