import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Offer from './Offer/Offer';
import GetOffer from './GetOffer/GetOffer';
import Benefits from './Benefits/Benefits';
import HowItWorks from './HowItWorks/HowItWorks';
import Advantages from './Advantages/Advantages';
import ClearCosts from './ClearCosts/ClearCosts';
import Newsletter from './Newsletter/Newsletter';
import Faq from './FAQ/Faq';

const Home = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Housellin</title>
        <meta
          name='description'
          content='Sprzedaj swoją nieruchomość za gotówkę.'
        />
        <meta property='og:title' content='Housellin' />
        <meta
          property='og:image'
          content='https://housellin.pl/assets/housellin_logo_v3.jpg'
        />
      </Helmet>
      <GetOffer />
      <Offer />
      <div className='container hiw'>
        <Benefits />
        <HowItWorks />
      </div>
      <Advantages />
      <ClearCosts />
      <Newsletter />
      <Faq />
    </Fragment>
  );
};

export default Home;
