import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Form from './Form';
import Map from './Map';
import Info from './Info';
import Description from './Description';
import './offerPage.scss';
import { getOfferByUrl } from '../../../actions/offer';

const Oferta = ({ getOfferByUrl, url, offer }) => {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const findOffer = async () => {
      const offer = await getOfferByUrl(url);
      if (offer.status === 500) {
        setRedirect(true);
      }
    };
    findOffer();
  }, [url]);

  const showNumber = (e) => {
    var x = e.target;
    debugger;
    if (e.target.className == 'banner__text banner__show-number') {
      e.target.innerHTML =
        '575 050 898' +
        "<a class='banner_phone-icon' href='tel:575-050-898'>" +
        "<i class='fas fa-phone-square-alt'></i>" +
        "<i class='fas fa-phone-alt'></i></a>";
      e.target.style.border = 'none';
      e.target.style.cursor = 'auto';
    }
  };

  const {
    name,
    price,
    location,
    additionalInfo,
    description,
    map,
    path,
    thumbnail,
    sold,
  } = offer;

  if (redirect) {
    return <Redirect to='/NoFound' />;
  }

  return (
    <div className='container'>
      <Helmet>
        <title>{name} - Housellin</title>
        <meta name='description' content={description[0]} />
        <meta property='og:title' content={name} />
        <meta property='og:image' content={thumbnail} />
        <meta
          property='og:url'
          content={`https://www.housellin.pl/oferta/${url}`}
        />
      </Helmet>
      <div className='columns is-multiline'>
        <div className='column is-12'>
          <ImageGallery items={path} lazyLoad={true} />
          <div className='offer-top'>
            <div className='offer-top__content'>
              <p className='offer-top__text-price'>
                {sold ? 'Sprzedane' : price}
              </p>
              <div className='offer-top__text-accent-wrapper'>
                <p className='offer-top__text-accent'>{name}</p>
              </div>
              <div className='offer-top__text-location-wrapper'>
                <p className='offer-top__text-location'>{location}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='column is-8'>
          <Description
            description={description}
            classStyle={'offer-description-tablet'}
          />
          <Map map={map} classStyle={'offer-description-tablet'} />
          <Form offer={url} classStyle={'offer-description-tablet'} />
        </div>
        <div className='column is-4'>
          <div className='banner'>
            <img className='banner__img' src='/assets/Dawid.png' />
            <img className='banner__sign' src='/assets/Dawid-sign.png' />
            <div className='banner__text-wrapper'>
              <span className='banner__text'>Zapraszam do kontaktu: </span>
              <div className='banner__show-number-wrapper'>
                <span className='banner__text'>+ 48 </span>
                <span
                  className='banner__text banner__show-number'
                  id='show-number'
                  onClick={(e) => showNumber(e)}
                >
                  Wyświetl numer
                </span>
              </div>
            </div>
          </div>
          <Info additionalInfo={additionalInfo[0]} />
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <Description
            description={description}
            classStyle={'offer-description-mobile'}
          />
        </div>
      </div>
      <div className='offer-description-mobile'>
        <div className='columns'>
          <div className='column is-8'>
            <Map map={map} />
          </div>
        </div>
        <div className='columns'>
          <div className='column is-8'>
            <Form offer={url} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  offer: state.offer.offer,
  url: ownProps.match.params.url,
});

const mapDispatchToProps = {
  getOfferByUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(Oferta);
