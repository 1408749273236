import React from 'react';
import { Link } from 'react-router-dom';
import './howItWorks.scss';
import ContractIcon from '../../../../utilities/ContractIcon';
import MeetingIcon from '../../../../utilities/MeetingIcon';
import FundsIcon from '../../../../utilities/FundsIcon';

const HowItWorks = () => {
  return (
    <div id='hiw' className='container margin-section'>
      <div className='columns'>
        <div className='column'>
          <h2 className='title-margin title-accent'>Dlaczego my?</h2>
          <div className='hiw__text-wrapper'>
            <p className='hiw__text font-medium-regular'>
              Jesteśmy zespołem młodych, prężnie działających osób z pełnym
              profesjonalizmu i zaangażowania podejściem do pracy.
            </p>
            <p className='hiw__text font-medium-regular'>
              Decydując się na sprzedaż nam swojej nieruchomości zawsze masz
              pewność, że cały proces przebiegnie szybko, bezproblemowo i przede
              wszystkim bezpiecznie. W kontaktach z klientami na pierwszym
              miejscu stawiamy szczerość i uczciwość.
            </p>
            <p className='hiw__text font-medium-regular'>
              Mieszkania kupujemy za kapitał własny, dlatego jesteśmy w stanie
              przeprowadzać transakcje nawet w 7 dni.
            </p>
            <p className='hiw__text font-medium-regular'>
              Zaufało Nam już dziesiątki zadowolonych klientów od których
              nabyliśmy do tej pory ponad 20 mieszkań w Koszalinie, Kołobrzegu
              oraz Trójmieście.
            </p>
          </div>
        </div>
      </div>
      <div className='columns is-multiline'>
        <div className='column is-offset-one-fifth-desktop is-full is-three-fifths-desktop'>
          <div className='hiw-item'>
            <div className='columns'>
              <div className='column is-4 hiw-item__icon-position icon-position--left'>
                <ContractIcon class={'hiw-item__icon'} fill='#f7e4a2' />
              </div>
              <div className='column is-8'>
                <div className='hiw-item__title font-medium'>
                  Wypełnij formularz online.
                </div>
                <div className='hiw-item__description font-small'>
                  Pamiętaj, że im więcej szczegółów uwzględnisz w formularzu
                  kontaktowym tym dokładniejsza będzie nasza wstępna wycena.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='column is-offset-one-fifth-desktop is-full is-three-fifths-desktop'>
          <div className='hiw-item'>
            <div className='columns columns-revers-mobile'>
              <div className='column is-8'>
                <div className='hiw-item__title font-medium'>
                  Spotkaj się z naszym agentem w celu potwierdzenia wstępnej
                  wyceny mieszkania.
                </div>
                <div className='hiw-item__description font-small'>
                  Przed spotkaniem z nami, nie musisz go w żaden sposób
                  przygotowywać.
                </div>
              </div>
              <div className='column is-4 hiw-item__icon-position icon-position--right'>
                <MeetingIcon class={'hiw-item__icon'} fill='#f7e4a2' />
              </div>
            </div>
          </div>
        </div>
        <div className='column is-offset-one-fifth-desktop is-full is-three-fifths-desktop'>
          <div className='hiw-item'>
            <div className='columns'>
              <div className='column is-4 hiw-item__icon-position icon-position--left'>
                <FundsIcon class={'hiw-item__icon'} fill='#f7e4a2' />
              </div>
              <div className='column is-8'>
                <div className='hiw-item__title font-medium'>
                  Wybierz datę i otrzymaj gotówkę w ciągu kilku dni.
                </div>
                <div className='hiw-item__description font-small'>
                  Po zaakceptowaniu oferty podpisujemy przedwstępną umowę
                  sprzedaży, umawiamy termin aktu notarialnego oraz przekazania
                  mieszkania.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='link-center'>
        <Link
          to={'/ankieta'}
          className='button button--main button--dark-background'
        >
          Uzyskaj Ofertę
        </Link>
      </div>
    </div>
  );
};

export default HowItWorks;
