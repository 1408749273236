import React, { useEffect } from 'react';
import './offer.scss';
import OfferList from './OfferList';
import { connect } from 'react-redux';
import { getOffers } from '../../../../actions/offer';

const Offer = ({ getOffers, activeOffers, soldOffers }) => {
  useEffect(() => {
    getOffers();
  }, []);

  return (
    <div id='nasze-nieruchomosci'>
      <OfferList title={'Aktywne oferty'} offers={activeOffers} />
      <OfferList title={'Nasze realizacje'} offers={soldOffers} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  offers: state.offer.offers,
  soldOffers: state.offer.soldPublishedOffers,
  activeOffers: state.offer.activePublishedOffers,
});

const mapDispatchToProps = {
  getOffers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Offer);
