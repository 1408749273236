import {
  GET_OFFERS,
  GET_OFFER,
  GET_ACTIVE_OFFERS,
  GET_SOLD_OFFERS,
  GET_SOLD_PUBLISHED_OFFERS,
  GET_ACTIVE_PUBLISHED_OFFERS,
} from '../actions/types';

const initialState = {
  offers: [],
  offer: {
    name: '',
    price: '',
    additionalInfo: [{ Powierzchnia: '' }],
    description: [],
    map: '',
    path: [],
    _id: '',
  },
  soldOffers: [],
  activeOffers: [],
  soldPublishedOffers: [],
  activePublishedOffers: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_OFFERS:
      return {
        ...state,
        offers: payload,
      };
    case GET_ACTIVE_OFFERS:
      return {
        ...state,
        activeOffers: payload,
      };
    case GET_SOLD_OFFERS:
      return {
        ...state,
        soldOffers: payload,
      };
    case GET_SOLD_PUBLISHED_OFFERS:
      return {
        ...state,
        soldPublishedOffers: payload,
      };
    case GET_ACTIVE_PUBLISHED_OFFERS:
      return {
        ...state,
        activePublishedOffers: payload,
      };
    case GET_OFFER:
      return {
        ...state,
        offer: payload,
      };
    default:
      return state;
  }
}
