import React from 'react';
import './aboutUs.scss';
import Form from '../OfferPage/Form';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = () => {
  return (
    <div className='container'>
      <div className='about-us'>
        <div className='about-us__photos'>
          <div className='container'>
            <div className='columns'>
              <div className='column'>
                <div className='about-us__img-wrapper'>
                  <img src='/assets/owners/tomek.jpg' alt='tomek' />
                  <p>Tomek</p>
                </div>
              </div>
              <div className='column'>
                <div className='about-us__img-wrapper'>
                  <img src='/assets/owners/dawid2.jpg' alt='dawid' />
                  <p>Dawid</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className='h2-like title-accent'>O nas</p>
        <div className='about-us__text-wrapper'>
          <p className='about-us__text'>
            Nasz zespół to grupa energicznych osób, bardzo dobrze zaznajomionych
            z branżą nieruchomości. Nasze doświadczenie oraz jakość naszych
            działań, jest rezultatem dziesiątek przeprowadzonych realizacji.
          </p>
          <p className='about-us__text'>
            Firma Housellin sp. z o.o. została założona w celu wypełnienia luki
            w branży obrotu nieruchomościami powstałej na skutek postępu
            technologicznego. Tradycyjny model sprzedaży mieszkań czy to z
            pomocą pośredników czy też na własną rękę od zawsze wiązał się z
            koniecznością poświęcenia po pierwsze cennego czasu, a drugie
            nakładów pieniężnych potrzebnych aby znaleźć klienta.
          </p>
          <p className='about-us__text'>
            Z Nami proces uzyskania wstępnej wyceny możesz w całości
            przeprowadzić online, a jak zdecydujesz się na sprzedaż to dopełnimy
            za Ciebie wszelkich formalności.
          </p>
          <p className='about-us__text'>
            Jesteśmy w stanie doprowadzić do finalizacji transakcji nawet do 7
            dni od otrzymania wstępnej wyceny. Mieszkania kupujemy zawsze z
            własnych środków, dzięki czemu w bardzo krótkim czasie możesz się
            cieszyć pieniędzmi na koncie uzyskanymi ze sprzedaży nieruchomości.
          </p>
        </div>
        <p className='h2-like title-accent'>Gdzie działamy?</p>
        <div className='about-us__text-wrapper'>
          <p className='about-us__text'>
            Housellin jest firmą lokalną i głównym obszarem naszej działalności
            są miasta w regionie pomorza środkowego: Koszalin, Kołobrzeg,
            Darłowo, Białogard, lub Mielno.
          </p>
          <p className='about-us__text'>
            Nie oznacza to jednak, że nie jesteśmy otwarci na zakup
            nieruchomości również w innych miastach. Ofertę zakupu mieszkania, z
            dowolnego miejsca Polsce, możesz od nas otrzymać korzystając z
            naszego formularza.
          </p>
        </div>
        <div className='about-us__button'>
          <Link
            to={'/ankieta'}
            className='button button--main button--dark-background'
          >
            Uzyskaj Ofertę
          </Link>
        </div>
        <div id='contact'>
          <Form />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
