import React, { useState } from 'react';
import Item from './Item';

const OfferList = ({ title, offers }) => {
  const [offerToShow, setOffersToShow] = useState(6);

  const loadMoreOffer = () => {
    const OTS = offerToShow + 3;
    setOffersToShow(OTS);
  };

  return (
    <div className='container margin-section'>
      <div>
        <h2 className='title-margin title-accent'>{title}</h2>
      </div>
      <div className='columns is-multiline'>
        {offers.map((offer, i) => {
          if (i < offerToShow) {
            return <Item key={offer._id} offer={offer} />;
          }
        })}
      </div>
      <div className='link-center'>
        {offers.length > offerToShow && (
          <button
            className='button button--main button--dark-background'
            onClick={() => loadMoreOffer()}
          >
            Więcej
          </button>
        )}
      </div>
    </div>
  );
};

export default OfferList;
