import React from 'react';

const MeetingIcon = props => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 437.432 437.432'
      fill={props.fill}
      className={props.class}
    >
      <path
        d='M297.711,154.547c-23.271,0.815-48.851,15.894-50.017,47.073c-0.943,25.233,8.595,45.694,26.17,56.135
      c7.462,4.434,15.83,6.65,24.197,6.65s16.734-2.217,24.197-6.65c17.575-10.441,27.113-30.901,26.17-56.135
      c-1.166-31.179-26.745-46.257-50.017-47.073C298.178,154.538,297.946,154.538,297.711,154.547z M328.443,202.368
      c0.662,17.687-5.315,31.608-16.398,38.192c-8.625,5.124-19.34,5.124-27.965,0c-11.083-6.584-17.061-20.505-16.398-38.192
      c0.687-18.362,15.693-27.175,30.381-27.819C312.749,175.192,327.756,184.006,328.443,202.368z M311.493,331c5.522,0,10,4.477,10,10
      s-4.478,10-10,10h-0.196c-5.522,0-10-4.477-10-10s4.478-10,10-10H311.493z M358.588,328.523l10.057,54.298
      c1.006,5.431-2.581,10.651-8.012,11.657c-0.615,0.114-1.229,0.175-1.833,0.175c-4.727,0-8.93-3.354-9.821-8.169l-10.057-54.274
      c-3.607-19.473-23.121-28.144-40.86-28.144s-37.254,8.806-40.86,28.28L249.332,375h61.052c5.522,0,10,4.477,10,10
      c0,5.522-4.477,10-10,10H237.31c-2.971,0-5.788-1.501-7.688-3.786c-1.899-2.284-2.686-5.475-2.145-8.396l10.059-54.297
      c5.7-30.772,34.507-44.547,60.526-44.547S352.888,297.75,358.588,328.523z M91.907,300.56c7.463,4.433,15.829,6.65,24.196,6.65
      s16.735-2.217,24.197-6.65c17.575-10.44,27.113-30.9,26.17-56.134c-1.166-31.179-26.745-46.258-50.017-47.074
      c-0.234-0.009-0.467-0.009-0.701,0c-23.271,0.816-48.851,15.895-50.017,47.075C64.792,269.659,74.331,290.119,91.907,300.56z
       M85.722,245.173c0.687-18.362,15.693-27.176,30.381-27.821c14.688,0.644,29.694,9.458,30.381,27.82
      c0.661,17.688-5.315,31.608-16.398,38.192c-8.624,5.123-19.34,5.124-27.964,0C91.038,276.781,85.061,262.86,85.722,245.173z
       M129.534,374c5.522,0,10,4.477,10,10s-4.478,10-10,10h-0.196c-5.522,0-10-4.477-10-10s4.478-10,10-10H129.534z M392.08,427
      c0,5.523-4.478,10-10,10H237.31c-5.522,0-10-4.477-10-10s4.478-10,10-10h144.77C387.603,417,392.08,421.477,392.08,427z
       M111.551,180.993c1.356,0.638,2.853,0.95,4.295,0.95c2.288,0,4.577-0.815,6.406-2.332L148.306,158h86.238
      c5.522,0,10.172-4.416,10.172-9.938V102h21.146l26.026,21.495c2.983,2.473,7.348,2.958,10.858,1.31
      c3.508-1.648,5.969-5.218,5.969-9.093V9.651c0-5.523-4.922-9.651-10.444-9.651H179.62c-5.522,0-9.904,4.128-9.904,9.651v17.941
      c0,5.523,4.478,10,10,10s10-4.477,10-10V20h99v74.429l-12.642-10.213C274.28,82.729,271.8,82,269.469,82h-24.753V65.883
      c0-5.523-4.649-9.883-10.172-9.883H115.892c-5.522,0-10.176,4.36-10.176,9.883v106.06
      C105.716,175.818,108.043,179.344,111.551,180.993z M125.716,76h99v62H144.7c-2.33,0-4.676,0.845-6.47,2.332l-12.514,10.332V76z
       M186.69,425.619c1.006,5.43-2.581,10.645-8.011,11.651c-0.616,0.114-1.229,0.162-1.834,0.162c-4.727,0-8.929-3.379-9.821-8.194
      l-10.061-54.325c-3.607-19.474-23.121-28.245-40.861-28.245c-17.739,0-37.253,8.606-40.859,28.078L67.374,417h62.051
      c5.522,0,10,4.477,10,10s-4.477,10-10,10H55.352c-2.971,0-5.788-1.099-7.688-3.383c-1.899-2.284-2.686-5.073-2.145-7.994
      l10.058-54.297c5.7-30.772,34.506-44.546,60.525-44.547c26.021,0,54.827,13.774,60.527,44.547L186.69,425.619z'
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default MeetingIcon;
