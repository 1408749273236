import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import './App.sass';
import './App.scss';
import Navbar from './components/layout/Navbar/Navbar';
import Home from './components/layout/Home/Home';
import Oferta from './components/layout/OfferPage/OfferPage';
import NotFound from './components/layout/NotFoundPage/NotFound';
import Survey from './components/layout/SurveyPage/Survey';
import Footer from './components/layout/Footer/Footer';
import Cookies from './components/layout/Cookies/Cookies';
import PrivacyPolicy from './components/layout/PrivacyPolicyPage/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';
import AboutUs from './components/layout/AboutUs/AboutUs';
import EditOffer from './components/layout/Admin/EditOffer';
import Alert from './components/layout/Alert/Alert';
import Login from './components/layout/Admin/Login';
import Admin from './components/layout/Admin/AdminPanel';
import PrivateRoute from './helpers/PrivateRoute';
import { loadUser } from './actions/auth';
import setAuthToken from './helpers/setAuthToken';
import store from './store';
import { connect } from 'react-redux';
import CreateOffer from './components/layout/Admin/CreateOffer';

const Scroll = withRouter(ScrollToTop);

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Router>
      <Fragment>
        <Scroll>
          <Navbar />
          <Alert />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/ankieta' component={Survey} />
            <PrivateRoute exact path='/admin' component={Admin} />
            <Route
              exact
              path='/polityka-prywatnosci'
              component={PrivacyPolicy}
            />
            <Route exact path='/o-nas' component={AboutUs} />
            <PrivateRoute
              exact
              path='/admin/create'
              component={CreateOffer}
            ></PrivateRoute>
            <Route exact path='/login' component={Login} />
            <PrivateRoute path='/admin/editOffer/:id' component={EditOffer} />
            <Route path='/oferta/:url' component={Oferta} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
          <Cookies />
        </Scroll>
      </Fragment>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(App);
