import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { login } from '../../../actions/auth';
import { Redirect } from 'react-router-dom';
import './login.scss';

const Login = ({ login, isAuthenticated }) => {
  const [formControls, setFormControls] = useState({
    isSubmited: false,
    formIsValid: false,
    email: {
      value: '',
      error: ['pole jest wymagane'],
      controlerIsValid: false,
      validationRule: {
        isRequired: true,
        isEmail: true,
      },
    },
    password: {
      value: '',
      error: ['pole jest wymagane'],
      controlerIsValid: false,
      validationRule: {
        isRequired: true,
      },
    },
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const updateFormControlers = {
      ...formControls,
    };

    const updateControler = {
      ...updateFormControlers[name],
    };

    var validation = formValidator(value, formControls[name].validationRule);

    updateFormControlers[name] = updateControler;

    updateControler.value = value;
    updateControler.error = validation[0];
    updateControler.controlerIsValid = validation[1];

    updateFormControlers.formIsValid =
      updateFormControlers.email.controlerIsValid &&
      updateFormControlers.password.controlerIsValid;

    setFormControls(updateFormControlers);
  };

  const formValidator = (value, rules) => {
    let isValid = {};
    let error = [];
    let controlerIsValid = true;
    for (let rule in rules) {
      switch (rule) {
        case 'isRequired':
          isValid[rule] = requiredValidator(value);
          if (!isValid[rule]) error.push('pole jest wymagane');
          controlerIsValid = controlerIsValid && isValid[rule];
          break;
        case 'isEmail':
          isValid[rule] = emailValidator(value);
          if (!isValid[rule]) error.push('mail jest nie poprawny');
          controlerIsValid = controlerIsValid && isValid[rule];
          break;
        default:
          controlerIsValid = true;
          break;
      }
    }
    return [error, controlerIsValid];
  };

  const requiredValidator = (value) => {
    return value.trim() !== '';
  };

  const emailValidator = (value) => {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFormControls({ ...formControls, isSubmited: true });
    if (formControls.formIsValid) {
      login(email.value, password.value);
    }
  };

  const { email, password, isSubmited } = formControls;

  if (isAuthenticated) {
    return <Redirect to='/admin' />;
  }

  return (
    <div className='container'>
      <div className='login'>
        <div className='box'>
          <div className='login__unlock'>
            <i class='fas fa-unlock'></i>
          </div>
          <form>
            <div className='field'>
              <label className='label'>Login</label>
              <div className='control'>
                <input
                  className='input'
                  type='text'
                  name='email'
                  onChange={(e) => handleChange(e)}
                  value={email.value}
                />
                {isSubmited && !email.controlerIsValid && (
                  <div className='offer-form__error'>{email.error[0]}</div>
                )}
              </div>
            </div>
            <div className='field'>
              <label className='label'>Hasło</label>
              <div className='control'>
                <input
                  className='input'
                  type='password'
                  name='password'
                  onChange={(e) => handleChange(e)}
                  value={password.value}
                />
                {isSubmited && !password.controlerIsValid && (
                  <div className='offer-form__error'>{password.error[0]}</div>
                )}
              </div>
            </div>
            <div className='field'>
              <div className='control level-right'>
                <input
                  type='submit'
                  value='Zaloguj'
                  className='button is-primary'
                  onClick={(e) => onSubmit(e)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, login })(Login);
