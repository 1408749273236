import React from 'react';
import { Link } from 'react-router-dom';
import './clearCosts.scss';

const ClearCosts = () => {
  return (
    <div id='clear-costs' className='container'>
      <h2 className='title-margin title-accent'>
        Jasne i przejrzyste koszty sprzedaży z Housellin
      </h2>
      <div className='columns is-gapless-dekstop is-marginless columns-reverse-desktop is-multiline'>
        <div className='column is-full-mobile is-paddingless-desktop'>
          <div className='clear-cost__desc-wrapper'>
            <div className='clear-cost__desc is-hidden-mobile'>
              Wyceny przygotowujemy w oparciu <br />o aktualne ceny transakcyjne
              mieszkań <br />w danej okolicy. Korzystamy przy tym <br />z
              dostępu do największej bazy danych zawierającej historię kilku
              milionów zawartych transakcji na Polskim rynku nieruchomości.
            </div>
            <div className='clear-cost__desc is-hidden-tablet'>
              Wyceny przygotowujemy w oparciu o aktualne ceny transakcyjne
              mieszkań w danej okolicy. Korzystamy przy tym z dostępu do
              największej bazy danych zawierającej historię kilku milionów
              zawartych transakcji na Polskim rynku nieruchomości.
            </div>
            <div className='link-center'>
              <Link
                to={'/ankieta'}
                className='button button--main button--dark-background'
              >
                Uzyskaj Ofertę
              </Link>
            </div>
          </div>
        </div>
        <div className='column is-full-mobile is-paddingless-desktop'>
          <img
            className='clear-cost__image'
            src='/assets/19.jpg'
            alt='desk-img'
          />
        </div>
      </div>
      <div className='columns is-gapless-dekstop is-marginless is-multiline columns-row-desktop '>
        <div className='column is-paddingless-desktop'>
          <img
            className='clear-cost__image'
            src='/assets/20.jpg'
            alt='note-img'
          />
        </div>
        <div className='column is-paddingless-desktop'>
          <div className='clear-cost__desc-wrapper'>
            <div className='clear-cost__equations'>
              <div className='clear-cost__equation-item'>
                <span></span>
                <span>wycena Housellin</span>
              </div>
              <div className='clear-cost__equation-item equation-item--red'>
                <i className='fas fa-minus'></i>
                <span>wycena napraw</span>
              </div>
              <div className='clear-cost__equation-item equation-item--red'>
                <i className='fas fa-minus'></i>
                <span>koszty transakcyjne</span>
              </div>
              <hr></hr>
              <div className='clear-cost__equation-item equation-item--green'>
                <i className='fas fa-equals'></i>
                <span className='text-accent'>Twoje wynagrodzenie</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClearCosts;
