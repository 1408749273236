import React, { Component } from 'react';
import './newsletter.scss';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const Form = ({ status, message, onSubmitted }) => {
  const submit = () => {
    let email = document.querySelector('.input-email').value;
    onSubmitted({
      EMAIL: email
    });
  };

  return (
    <div className='container'>
      <div className='newsletter'>
        <h2 className='text-accent-yellow'>
          ZAPISZ SIĘ, ABY NIE PRZEGAPIĆ NOWYCH OFERT
        </h2>
        <span className='newsletter__title font-medium-regular has-text-centered'>
          ZAPISZ SIĘ DO NEWSLETTERA:
        </span>
        <div className='newsletter__form-wrapper'>
          <div className='field field--newsletter'>
            <div className='control'>
              <input
                className='input input--newsletter input-email'
                type='email'
                placeholder='Wprowadź adres email'
              />
            </div>
            <div className='control control--center'>
              <button
                onClick={submit}
                className='button button--submit-newsletter button--dark-background'
              >
                Zapisz Się
              </button>
            </div>
          </div>
          <div className='newsletter__message'>
            {status === 'error' && (
              <div
                style={{ color: 'red' }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === 'success' && (
              <div style={{ color: 'green' }}>Dziękujemy za subskrybcję !</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

class Newsletter extends Component {
  render() {
    const url =
      'https://gmail.us20.list-manage.com/subscribe/post?u=ec24886f7a4281ac24bc2cf74&amp;id=7e88b667ee';
    return (
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <Form
            status={status}
            message={message}
            onSubmitted={formData => subscribe(formData)}
          />
        )}
      />
    );
  }
}

export default Newsletter;
